import { EventBus } from "@/event-bus";
import { ReconciliationStatus } from "@/interfaces/traceability";
import { messagesService } from "@/services/messages.service";
import { traceabilityService } from "@/services/traceability.service";
import { PageNavAction } from "@/types/types";
import compareDesc from "date-fns/compareDesc";
import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import LoadingWindowComponent from "../loadingWindow/loadingWindow.component";
import Template from "./StrainsReconciliation.template.vue";

@Component({
  mixins: [Template],
  components: {
    LoadingWindowComponent
  }
})
export default class StrainsReconciliationComponent extends Vue {
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  public status: ReconciliationStatus[] = [];
  public selectedOption: "bdi" | "metrc" = "bdi";
  public inProgress = false;
  public fetchedStatus = false;
  public showLoadingWindow = false;
  public metrcErrors: Array<{ message: string }> = [];
  public recOptions = [
    {
      text: "BiotrackTHC",
      value: "bdi"
    },
    {
      text: "Metrc",
      value: "metrc"
    }
  ];

  protected intervalId!: number;

  public runSync() {
    if (this.selectedOption === "bdi") {
      traceabilityService.reconciliateBiotrack();
    } else {
      traceabilityService.reconciliateMetrc();
    }
    this.inProgress = true;
    this.showLoadingWindow = true;
    this.openLoadingWindow();
  }

  public async mounted() {
    this.setPageNav({
      title: this.$t("metrc.strains_reconciliation_t").toString(),
      isLoading: () => !this.fetchedStatus
    });
    await this.getStatus();
    this.fetchedStatus = true;
    this.pollStatus();
  }

  public beforeDestroy() {
    clearInterval(this.intervalId);
  }

  protected openLoadingWindow() {
    this.$modals
      .load(LoadingWindowComponent, {
        closable: false,
        size: "fit",
        positionY: "center",
        positionX: "center",
        backdrop: true
      })
      .then(
        _ => {
          messagesService.renderSuccessMessage("metrc.strains_reco_ok");
        },
        () => {
          // nothing to do on catch
        }
      );
  }

  protected async getStatus() {
    this.status = await traceabilityService.getReconciliationStatus();
    const running = this.status.find(s => !s.finish);
    if (running) {
      this.inProgress = true;
      this.selectedOption =
        running.priority_system === "BIOTRACK" ? "bdi" : "metrc";
    } else {
      this.inProgress = false;
      const statusOrdered = this.status.sort((stat, stat1) =>
        compareDesc(new Date(stat.finish), new Date(stat1.finish))
      );
      this.selectedOption =
        statusOrdered[0] && statusOrdered[0].priority_system === "BIOTRACK"
          ? "bdi"
          : "metrc";
      if (statusOrdered[0] && statusOrdered[0].errors) {
        this.metrcErrors = statusOrdered[0].errors;
        this.showLoadingWindow = true;
      } else {
        this.showLoadingWindow = false;
        this.metrcErrors = [];
      }

      EventBus.$emit("mtrcLoadingEvent", {
        show: this.showLoadingWindow,
        errorList: this.metrcErrors
      });
    }
  }

  protected async pollStatus() {
    this.intervalId = setInterval(this.getStatus, 5000);
  }
}
